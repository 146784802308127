<template>
    <div class="p-3">
        <div class="text-center">
            <el-radio-group v-model="status" @change="loadSettlements(true)">
                <el-radio-button :label="null">全部</el-radio-button>
                <el-radio-button :label="1">结算中</el-radio-button>
                <el-radio-button :label="2">已结算</el-radio-button>
            </el-radio-group>
        </div>

        <el-card shadow="never" class="mt-3">
            <div class="row no-gutters">
                <div class="col">
                    <el-date-picker v-model="beginTime" placeholder="开始日期" style="width: 160px;"
                        @change="loadSettlements(true)" />
                    <el-date-picker v-model="endTime" placeholder="开始日期" class="ml-2" style="width: 160px;"
                        @change="loadSettlements(true)" />
                </div>
            </div>
            <el-table :data="settlements.data" class="mt-2">
                <el-table-column prop="statusName" label="状态" sortable width="100" />
                <el-table-column prop="billNumber" label="单据编号" sortable width="200" />
                <el-table-column prop="supplierName" label="供应商" sortable />
                <el-table-column prop="amount" label="结算金额（元）" sortable width="150" />
                <el-table-column label="操作" fixed="right" width="100">
                    <template #default="scope">
                        <el-button type="text" @click="settleSettlement(scope.row.id)">
                            结算付款
                        </el-button>
                    </template>
                </el-table-column>
            </el-table>
            <el-pagination layout="total,->,prev,pager,next" :total="settlements.totalRecords"
                :current-page="settlementsPagination.currentPage" :page-size="settlementsPagination.pageSize"
                class="mt-3" @current-change="settlementsPaginationCurrentChange" />
        </el-card>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                status: 1,
                beginTime: new Date(new Date().getFullYear(), 0, 1),
                endTime: new Date(),
                settlements: {},
                settlementsPagination: {
                    currentPage: 1,
                    pageSize: 10,
                },
            };
        },
        methods: {
            async loadSettlements(resetPage = false) {
                if (resetPage) {
                    this.settlementsPagination.currentPage = 1;
                }

                let response = await this.$axios.get('/api/Settlement/GetSettlements', {
                    params: {
                        status: this.status,
                        beginTime: this.$moment(this.beginTime).format('YYYY/M/D'),
                        endTime: this.$moment(this.endTime).format('YYYY/M/D'),
                        pageIndex: this.settlementsPagination.currentPage - 1,
                        pageSize: this.settlementsPagination.pageSize,
                    }
                });
                this.settlements = response.data;
            },
            settlementsPaginationCurrentChange(page) {
                this.settlementsPagination.currentPage = page;
                this.loadSettlements();
            },
            async settleSettlement(id) {
                if (!confirm('确定要结算付款吗？')) {
                    return;
                }

                await this.$axios.post('/api/Settlement/settleSettlement', { id: id });
                this.loadSettlements();
            },
        },
        created() {
            this.loadSettlements();
        },
    };
</script>